var $ = jQuery.noConflict();

$(window).on('load', function () {
    let method = 'front';
    if ($('body').hasClass('wp-admin'))
        method = 'wp-admin';

        var initializeBlock = function ($block) {

        // Place for your code

          $('.gform_drop_instructions').click(function(){
            $(this).parents('.gform_drop_area').find('.gform_button_select_files').trigger('click');
          });
    };

    window.onload = function () {
        initializeBlock($(this));
    }();

    if (window.acf) {
        window.acf.addAction('render_block_preview/type=image-carousel', initializeBlock);
    }
});
